/**
 * useStoryblokCMS Composable
 *
 * Purpose:
 * This composable provides functionality to fetch a story from the Storyblok CMS.
 * It determines the mode (draft or published) based on query parameters or local storage,
 * and adjusts the path based on route parameters and the current locale.
 *
 * Returns:
 * - story: The fetched story data.
 * - error: Any errors encountered during the fetch.
 * - fetchStory: A function that fetches the story based on a provided path.
 *
 * Usage Example:
 * ```javascript
 * const { story, error, fetchStory } = useStoryblokCMS();
 * ```
 */

import type { SeoStoryblok } from '~/interfaces/storyblok/storyblok'

interface StoryContent {
  seo?: SeoStoryblok[]
}

interface StoryResult {
  status?: number
  response?: string
  content?: StoryContent
  [key: string]: any
}

export function useStoryblokCMS(path: string) {
  const storyblokApiInstance = useStoryblokApi()
  const route = useRoute()
  const config = useRuntimeConfig()
  const { $preview } = useNuxtApp()
  const version = $preview ? 'draft' : 'published'

  // If no path is provided, use the default locale
  if (path === '') { path = 'en' }

  // Replace the placeholders in the path with the actual route parameters
  if (Array.isArray(route.params.slug)) { path = path.replace(':slug', route.params.slug.join('/')) }
  else { path = path.replace(':slug', route.params.slug) }

  path = path.replace(':version', route.params.version as string)

  const { data: story, pending, error } = useAsyncData(
    `${path}`,
    async () => {
      const { data } = await storyblokApiInstance.get(`cdn/stories/${path}`, {
        version,
        cv: Date.now(),
      })
      return data?.story
    },
  )

  if (error.value) {
    if (error.value && error.value.message === 'Not Found') { throw createError({ statusCode: 404, statusMessage: 'Page Not Found', fatal: true }) }
    else { throw createError({ statusCode: 500, statusMessage: 'We have troubles loading the page. Please try again in a few minutes', fatal: true }) }
  }

  // Storyblok bridge for live updates.
  onMounted(() => {
    if ($preview && story.value && story.value.id) {
      useStoryblokBridge(
        story.value.id,
        evStory => (story.value = evStory),
      )
    }
  })

  // Set the SEO tags based on the story data.
  function setSEO(story: Ref<StoryResult | null>) {
    useSeoMeta({
      robots: () => story.value?.content?.seo?.[0]?.robots?.join(',') || 'index,follow',
      title: () => story.value?.content?.seo?.[0]?.title || 'BeyondBreath',
      description: () => story.value?.content?.seo?.[0]?.description,
      ogTitle: () => story.value?.content?.seo?.[0]?.og_title,
      ogType: () => 'website',
      ogDescription: () => story.value?.content?.seo?.[0]?.og_description,
      ogImage: () => story.value?.content?.seo?.[0]?.og_image?.filename || `${config.public.publicSiteUrl}/img/share.png`,
      ogUrl: () => config.public.publicSiteUrl + route.path,
      twitterTitle: () => story.value?.content?.seo?.[0]?.og_title,
      twitterDescription: () => story.value?.content?.seo?.[0]?.description,
      twitterImage: () => story.value?.content?.seo?.[0]?.og_image?.filename || `${config.public.publicSiteUrl}/img/share.png`,
      twitterCard: () => 'summary',
    })
  }

  return {
    story,
    pending,
    error,
    setSEO,
  }
}
